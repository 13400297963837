



















































































import BouncingLoader from "@/components/BouncingLoader.vue";
import Button from "@/components/Button.vue";
import GameParams from "@/components/GameParams.vue";
import IncrementForm from "@/components/IncrementForm.vue";
import SockController, { SOCK_ACTIONS } from "@/sock/SockController";
import IRCClient, { IRCTypes } from "@/twitch/IRCClient";
import IRCEvent from "@/twitch/IRCevent";
import TwitchExtensionHelper from "@/twitch/TwitchExtensionHelper";
import TwitchMessageType from "@/twitch/TwitchMessageType";
import Api from "@/utils/Api";
import Utils from "@/utils/Utils";
import PlaylistData from "@/vo/PlaylistData";
import SocketEvent from "@/vo/SocketEvent";
import gsap from "gsap/all";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component({
	components:{
		Button,
		GameParams,
		IncrementForm,
		BouncingLoader,
	}
})
export default class TwitchLobby extends Vue {

	@Prop({default:""})
	public playlistids:string;

	@Prop({default:""})
	public mode:string;

	public urlOBS:string = null;
	public selectedPlaylists:PlaylistData[] = null;

	public ready:boolean = false;
	public disposed:boolean = false;
	public sendingToChat:boolean = false;
	public maxPlayers:number = 100;
	public gamesCount:number = 10;
	public tracksCount:number = 4;
	public gameDuration:number = 60;
	public acceptDuration:number = 3;
	public acceptAlbum:boolean = false;
	public chatConfirm:boolean = true;
	public zoomLevel:number = 1.4;
	public expertMode:string[] = [];
	public command:string = "!mbt";
	public players:IRCTypes.Tag[] = [];
	public ircMessageHandler:any;
	public socketMessageHandler:any;

	public get inviteMessage():string {
		return "SingsNote Join the game with the following command \""+this.command+"\"";
	}

	public getUserClasses(u:IRCTypes.Tag):any {
		let res:any = {};
		if(u.color) res["background-color"] = u.color;
		//If background is dark enough, make the label bright so it stays readable
		let lum = Utils.getLuminance(u.color);
		if(lum < .72) res["color"] = "#ffffff";
		return res;
	}

	public async mounted():Promise<void> {
		this.ready = IRCClient.instance.connected;
		if(!this.ready) {
			try {
				let res = await IRCClient.instance.initialize(this.$store.state.twitchLogin, this.$store.state.twitchOAuthToken.access_token);
			}catch(error) {
				this.$router.push({name:"twitch/auth"});
				return;
			}
			this.ready = true;
		}
		
		if(!this.playlistids) {
			this.$router.push({name:"twitch/auth"});
			return;
		}
		
		let route = {name:'twitchobs/viewer', params:{twitchLogin:this.$store.state.twitchLogin}};
		this.urlOBS = document.location.origin+this.$router.resolve(route).href;

		let playlists:PlaylistData[] = this.$store.state.playlistsCache;
		this.selectedPlaylists = [];
		
		for (let i = 0; i < playlists.length; i++) {
			if(this.playlistids.indexOf(playlists[i].id) > -1) {
				this.selectedPlaylists.push(playlists[i]);
			}
		}
		
		this.selectedPlaylists.sort((a, b) => {
			if(a.name > b.name) return 1;
			if(a.name < b.name) return -1;
			return 0;
		});

		this.broadcastInfosToExtension();

		this.ircMessageHandler = (e:IRCEvent) => this.onIrcMessage(e);
		IRCClient.instance.addEventListener(IRCEvent.MESSAGE, this.ircMessageHandler);

		let res = await Api.post("twitch/user", {token:IRCClient.instance.token});
		SockController.instance.connect();
		SockController.instance.keepBroadcastingLastMessage = true;
		SockController.instance.user = {
											name:"controler",
											id:res.user.user_id+"_ctrl",
											offline:false,
											score:0,
											handicap:0,
										};
		this.socketMessageHandler = (e:SocketEvent) => this.onSocketMessage(e);
		SockController.instance.addEventListener(SOCK_ACTIONS.SEND_TO_UID, this.socketMessageHandler);
	}

	public beforeDestroy():void {
		this.disposed = true;
		IRCClient.instance.removeEventListener(IRCEvent.MESSAGE, this.ircMessageHandler);
		SockController.instance.removeEventListener(SOCK_ACTIONS.SEND_TO_UID, this.socketMessageHandler);
	}

	/**
	 * Called when receiving a message from IRC
	 */
	public onIrcMessage(e:IRCEvent):void {
		if(e.tags["message-type"] == "action" && e.message == this.inviteMessage) {
			this.sendingToChat = false;
		}

		//User joins
		if(e.message.toLowerCase() == this.command.toLowerCase()) {
			if(this.players.length == this.maxPlayers) return;//Max players count reached

			for (let i = 0; i < this.players.length; i++) {
				const p = this.players[i];
				if(p["user-id"] == e.tags["user-id"]) return;//Uer already registered
			}
			this.players.push(e.tags);
		}
	}

	/**
	 * Called when receiving a message via socket
	 * This is used when the broadcaster uses the embeded controls
	 * on his/her stream extension
	 */
	public onSocketMessage(e:SocketEvent):void {
		if(e.data == "!start") {
			this.startGame();
		}
	}

	/**
	 * Sends a message to IRC
	 */
	public sendToChat():void {
		this.sendingToChat = true;
		setTimeout(() => {
			IRCClient.instance.sendMessage(this.inviteMessage);
		}, 250);
	}

	/**
	 * Starts the game
	 */
	public startGame():void {
		// this.$store.dispatch("setTwitchAllowedUsers", this.players);
		let params:any = {
			playlistids:this.playlistids,
			tracksCount:this.tracksCount.toString(),
			gamesCount:this.gamesCount.toString(),
			gameDuration:this.gameDuration.toString(),
			acceptAlbum:this.acceptAlbum? 1 : 0,
			chatConfirm:this.chatConfirm? 1 : 0,
			mode:this.mode,
			zoom:this.zoomLevel,
			acceptDuration:this.acceptDuration,
		}
		if(this.expertMode && this.expertMode.length > 0) {
			params.expertMode = this.expertMode.join(",");
		}
		
		this.$router.push({name:"twitch/controls", params});
	}

	@Watch("expertMode")
	public onExpertModeChange():void {
		this.broadcastInfosToExtension();
	}

	@Watch("zoomLevel")
	public onZoomChange():void {
		this.broadcastInfosToExtension();
	}

	/**
	 * Broadcast current config infos to clients
	 */
	private broadcastInfosToExtension():void {
		let playlists = [];
		for (let i = 0; i < this.selectedPlaylists.length; i++) {
			const p = this.selectedPlaylists[i];
			playlists.push({name:p.name, cover:p.cover, id:p.id})
		}
		let data = {
			playlists,
			expert:this.expertMode,
			zoom:this.zoomLevel,
		}
		if(this.mode == "twitchObs") {
			let event = {
				target:this.$store.state.twitchLogin+"_ext",
				data:{action:SOCK_ACTIONS.SEND_TO_UID, data:{actionType:TwitchMessageType.PLAYLISTS, state:data}}
			};
			SockController.instance.sendMessage({action:SOCK_ACTIONS.SEND_TO_UID, data:event});
		}else{
			TwitchExtensionHelper.instance.broadcast(TwitchMessageType.PLAYLISTS, data);
		}
	}
	
	public copyURL():void {
		Utils.copyToClipboard(this.urlOBS);
		gsap.set(this.$refs.url, {filter:"brightness(100%)"})
		gsap.from(this.$refs.url, {duration:.5, filter:"brightness(200%)"})
	}

	public selectText(e:Event):void {
		let div = <HTMLDivElement>e.target;
		var selection = window.getSelection();
		var range = document.createRange();
		range.selectNodeContents(div);
		selection.removeAllRanges();
		selection.addRange(range);

	}
}
